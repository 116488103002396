/* eslint-disable no-undef */
import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";
import "./following-sidebar.js";
import "./thumbs-swiper.js";

const nanoid = () => {
	const dateString = Date.now().toString(36);
	const randomness = Math.random().toString(36).substr(2);
	return dateString + randomness;
};

let root = document.documentElement;
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
root.style.setProperty("--vh", `${vh}px`);

// We listen to the resize event
window.addEventListener("resize", () => {
	// We execute the same script as before
	let vh = window.innerHeight * 0.01;
	root.style.setProperty("--vh", `${vh}px`);
});

const header = document.getElementsByClassName(`header`)[0];
// Header scroll
const scrollContainer = () => {
	return root || document.body;
};

function scrollableHeader() {
	if (scrollContainer().scrollTop > 10) {
		header.classList.add("scrolled");
		root.style.setProperty(
			"--header-height",
			"var(--header-height-scrolled)"
		);
	} else if (scrollContainer().scrollTop === 0) {
		header.classList.remove("scrolled");
		root.style.setProperty("--header-height", "var(--header-height-full)");
	}
}

if (header) {
	document.addEventListener("scroll", scrollableHeader);

	scrollableHeader();
}

// menu handlers

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
	document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable =
	$(window).outerWidth() <= mobileMenuStartPoint ||
	$(".header-mobile").length;


if (isMobileMenuEnable) {
	$(".menu-item.dropdown").on('click',".arrow-dropdown", function (e) {
		console.log(132432);
		$(this).next().toggleClass('active');
		$(this).toggleClass('active');
		$(this).prev().toggleClass('active');
	});
	$(".menu-item.dropdown").on('click',".dropdown-toggle", function (e) {
		if(!$(this).hasClass('active')) {
			e.preventDefault();
			$(this).addClass('active');
			$(this).next().addClass('active');
			$(this).parent().find('.dropdown-menu').addClass('active');
		}
	});
}

// toggle menu handler
function menuToggle() {
	$(".header").toggleClass("active");
	$(".menu-toggle").toggleClass("active");
	$(".navbar-nav").toggleClass("active");
	$(".header-close-wrapper").toggleClass("active");
	// LockScroll when burger open and enable when closed and enable scroll on menu
	scrollLock.getScrollState()
		? scrollLock.disablePageScroll(document.querySelector(".navbar-nav"))
		: scrollLock.enablePageScroll();
}
//menu update function
function updateMenu() {
	isMobileMenuEnable =
		$(window).outerWidth() <= mobileMenuStartPoint ||
		$(".mobile-header").length;
	if (!isMobileMenuEnable) {
		$(".dropdown-menu").css("display", "");
		$(".header-close-wrapper").removeClass("active");
		$(".menu-item.active").removeClass("active");
		$(".navbar-nav").removeClass("active");
		$(".menu-toggle").removeClass("active");
		// LockScroll when burger open and enable when closed
		scrollLock.enablePageScroll();
	}
}
$(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles
$(".header-close-wrapper").click(menuToggle); //menu toggles

// Gallery Swiper
const gallerySwiperSection = document.getElementById("swiper-gallery");
if (gallerySwiperSection) {
	const gallerySlides =
		gallerySwiperSection.querySelectorAll(".swiper-slide");
	new Swiper("#swiper-gallery", {
		slidesPerView: 1,
		effect: "creative",

		loop: gallerySlides.length > 2,
		loopedSlides: 5,

		speed: 1000,
		autoplay: {
			delay: 1200,
		},

		breakpoints: {
			// when window width is >= 320px
			320: {
				creativeEffect: {
					limitProgress: 1,
					prev: {
						translate: ["-80%", 0, 0],
						scale: 0.8,
					},
					next: {
						translate: ["80%", 0, 0],
						scale: 0.8,
					},
				},
			},
			// when window width is >= 1023px
			1023: {
				creativeEffect: {
					limitProgress: 2,
					prev: {
						translate: ["-21%", 0, 0],
						scale: 0.8,
					},
					next: {
						translate: ["100%", 0, 0],
					},
				},
			},
		},
	});
}

// Testimonials Swiper
const testimonialsSwiperSection = document.getElementById(
	"swiper-testimonials"
);

if (testimonialsSwiperSection) {
	new Swiper("#swiper-testimonials", {
		slidesPerView: 1,
		spaceBetween: 20,

		// speed: 500,
		// autoplay: {
		// 	delay: 3000,
		// },

		navigation: {
			nextEl: ".swiper-testimonials-next",
			prevEl: ".swiper-testimonials-prev",
		},

		breakpoints: {
			640: {
				slidesPerView: 2,
				spaceBetween: 32,
			},
		},
	});
}

// Popular Goods Swiper
const goodsSwiperSectionArr = document.querySelectorAll(".swiper-goods");

if (goodsSwiperSectionArr.length) {
	// Make Swiper for all .swiper-goods blocks (on one page we have two of this)
	goodsSwiperSectionArr.forEach((elem) => {
		new Swiper(elem, {
			slidesPerView: "auto",
			spaceBetween: 20,

			navigation: {
				nextEl: ".swiper-popular-goods-next",
				prevEl: ".swiper-popular-goods-prev",
			},

			breakpoints: {
				1024: {
					spaceBetween: 32,
				},
			},
		});
	});

	const swiperGoodsImageGalleryArr = [
		...document.querySelectorAll(".swiper-popular-goods-image-gallery"),
	];

	swiperGoodsImageGalleryArr.forEach((gallerySwiper) => {
		// Hide Pagination on Swiper Galleries which length is 1
		const amountOfSlides =
			gallerySwiper.querySelectorAll(".swiper-slide").length;
		if (amountOfSlides === 1) {
			gallerySwiper
				.querySelector(".swiper-navigation")
				.classList.add("hidden");
		}

		// Make Unique ID for every GallerySwiper
		const swiperId = nanoid();
		gallerySwiper.classList.add(swiperId);

		const nextId = nanoid();
		const prevId = nanoid();
		gallerySwiper
			.querySelector(".swiper-popular-goods-image-gallery-next")
			.classList.add(nextId);
		gallerySwiper
			.querySelector(".swiper-popular-goods-image-gallery-prev")
			.classList.add(prevId);

		new Swiper(`.${swiperId}`, {
			slidesPerView: 1,
			loop: amountOfSlides > 1,
			nested: true,

			navigation: {
				nextEl: `.${nextId}`,
				prevEl: `.${prevId}`,
			},

			pagination: {
				el: ".swiper-popular-goods-image-gallery-pagination",
				type: "bullets",
				clickable: true,
				dynamicBullets: true,
				dynamicMainBullets: 3,
			},
		});
	});
}

// Pass single element
const choicesDOM = document.querySelector(["[data-choices]"]);
if (choicesDOM) {
	new Choices("[data-choices]", {
		searchEnabled: false,
		itemSelectText: "Вибрати",
	});
}
